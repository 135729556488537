import { Editor } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";

export interface FixedMenuPluginProps {
  /**
   * The plugin key.
   * @type {PluginKey | string}
   */
  pluginKey: PluginKey | string;

  /**
   * The editor instance.
   */
  editor: Editor;

  /**
   * The DOM element that contains the fixed menu.
   * @type {HTMLElement}
   */
  element: HTMLElement;

  /**
   * Whether the menu should be rendered as a portal of the editor element.
   * @type {boolean}
   * @default true
   */
  portal?: boolean;
}

export type FixedMenuViewProps = FixedMenuPluginProps & {
  view: EditorView;
};

export class FixedMenuView {
  public editor: Editor;

  /**
   * The DOM element that contains the fixed menu.
   * If `portal` is `true`, this will be the editor element.
   */
  private readonly domElement: HTMLElement;

  public element: HTMLElement;

  public portal: boolean;

  private view: EditorView;

  /**
   * Appends or removes the element from the DOM depending on whether the editor is editable.
   */
  appendOrRemoveElement() {
    const { element: editorElement } = this.editor.options;
    const isAttached = !!editorElement.parentElement;
    if (!isAttached && this.domElement === null) return;
    const isEditorContainsMenu = this.domElement.contains(this.element);
    if (this.view.editable && !isEditorContainsMenu) {
      this.domElement.appendChild(this.element);
    }

    if (!this.view.editable) {
      this.element.remove();
    }
  }

  constructor({ editor, element, view, portal = true }: FixedMenuViewProps) {
    this.editor = editor;
    this.element = element.firstChild as HTMLElement;
    this.domElement = element;
    this.portal = portal;
    this.view = view;
    if (this.portal) {
      this.domElement.remove();
      this.domElement = this.editor.options.element as HTMLElement;
    }
    this.appendOrRemoveElement();
  }

  update() {
    this.appendOrRemoveElement();
  }

  destroy() {
    this.domElement.remove();
  }
}

/**
 * Determines whether to display a fixed menu, bound to the editor's editable state.
 * In `portal` mode, the menu will be rendered as a child of the editor container.
 * Useful for focus handling.
 */
export const FixedMenuPlugin = (options: FixedMenuPluginProps) => {
  return new Plugin({
    key:
      typeof options.pluginKey === "string"
        ? new PluginKey(options.pluginKey)
        : options.pluginKey,
    view: (view) => new FixedMenuView({ view, ...options }),
  });
};
