/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TypedDocumentNode, gql, useApolloClient } from "@apollo/client";
import { getSignature } from "@lemonde/signature";
import { differenceBy } from "lodash";
import { forwardRef, memo, useEffect, useRef, useState } from "react";
import { Clickable } from "swash/Clickable";
import { useDialogStore } from "swash/Dialog";
import { SelectButton, SelectPopover } from "swash/Select";
import { Tooltip } from "swash/Tooltip";
import { usePrevious } from "swash/utils/usePrevious";
import { useStoreState } from "swash/utils/useStoreState";
import { useComboboxStore } from "swash/v2/Combobox";
import {
  RemoteSelectList,
  RemoteSelectState,
  useRemoteSelectState,
} from "swash/v2/RemoteSelect";

import { AuthorAvatar } from "@/components/AuthorAvatar";
import { useAmplitude } from "@/containers/Amplitude";
import { mergeConnections, useSafeQuery } from "@/containers/Apollo";
import {
  AuthorsNotificationDialog,
  logNotificationsEvent,
} from "@/containers/article/AuthorsNotificationDialog";
import {
  ArticleAuthorsSelect_articleFragment,
  ArticleAuthorsSelect_authorFragment,
  ArticleAuthorsSelect_authorsQuery,
  ArticleAuthorsSelect_authorsQueryVariables,
} from "@/gql-types";

const AuthorFragment = gql`
  fragment ArticleAuthorsSelect_author on Author {
    id
    verified
    longName
    shortName
    origin
    ...AuthorAvatar_author
  }
  ${AuthorAvatar.fragments.author}
`;

export const ArticleFragment = gql`
  fragment ArticleAuthorsSelect_article on Article {
    id
    title
    sections {
      id
      name
    }
    authors {
      ...ArticleAuthorsSelect_author
    }
  }

  ${AuthorFragment}
`;

const AuthorsQuery: TypedDocumentNode<
  ArticleAuthorsSelect_authorsQuery,
  ArticleAuthorsSelect_authorsQueryVariables
> = gql`
  query ArticleAuthorsSelect_authors($search: String, $offset: Int) {
    authors(offset: $offset, where: { search: $search, enabled: true }) {
      nodes {
        ...ArticleAuthorsSelect_author
      }
      pageInfo {
        hasMore
      }
      totalCount
    }
  }

  ${AuthorFragment}
`;

export type ArticleAuthor = ArticleAuthorsSelect_authorFragment;

type AuthorQueryVariables = ArticleAuthorsSelect_authorsQueryVariables;

type AuthorQueryData = ArticleAuthorsSelect_authorsQuery;

export type ArticleAuthorsSelectStateProps = {
  value: ArticleAuthor[];
  onChange: (value: ArticleAuthor[]) => void;
  required?: boolean;
};

export const useArticleAuthorsSelectState = (
  props: ArticleAuthorsSelectStateProps,
): RemoteSelectState<ArticleAuthor> => {
  const combobox = useComboboxStore();
  const comboboxValue = useStoreState(combobox, "value");

  const client = useApolloClient();
  const queryResult = useSafeQuery<AuthorQueryData, AuthorQueryVariables>(
    AuthorsQuery,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: { search: comboboxValue },
    },
  );
  const data = queryResult.data ?? queryResult.previousData;

  const enumSelect = useRemoteSelectState<ArticleAuthor, ArticleAuthor[]>({
    value: props.value,
    onChange: props.onChange,
    data: data
      ? {
          items: data.authors.nodes ?? ([] as ArticleAuthor[]),
          hasMore: data.authors.pageInfo.hasMore ?? false,
          totalCount: data.authors.totalCount ?? 0,
        }
      : null,
    combobox,
    loading: queryResult.loading,
    fetchMore: (previousData) => {
      queryResult.fetchMore({
        variables: { offset: previousData.items.length },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!previousResult.authors) return previousResult;
          return {
            ...previousResult,
            authors: mergeConnections(
              previousResult.authors,
              fetchMoreResult.authors,
            ),
          };
        },
      });
    },
    getItem: (id) => {
      const value = client.readFragment({
        id: `Author:${id}`,
        fragment: AuthorFragment,
        fragmentName: "ArticleAuthorsSelect_author",
      });
      if (!value) {
        throw new Error("No author found");
      }
      return value;
    },
    labelSelector: (author) => {
      if (author.shortName) return `${author.longName} (${author.shortName})`;
      return author.longName;
    },
    valueSelector: (author) => author.id.toString(),
    iconSelector: (author) => <AuthorAvatar author={author} />,
    required: props.required ?? false,
  });
  return enumSelect;
};

useArticleAuthorsSelectState.fragments = {
  article: ArticleFragment,
};

export type ArticleAuthorsSelectListProps = {
  state: RemoteSelectState<ArticleAuthor>;
};

export const ArticleAuthorsSelectList = (
  props: ArticleAuthorsSelectListProps,
) => {
  return <RemoteSelectList placeholder="Signataires..." state={props.state} />;
};

type ArticleAuthorsButton = {
  authors: ArticleAuthor[];
  placeholder?: string;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  disabled?: boolean;
};

export const ArticleAuthorsButton = memo(
  forwardRef<HTMLButtonElement, ArticleAuthorsButton>(
    ({ authors, placeholder, ...props }, ref) => {
      return (
        <Clickable ref={ref} className="h-full p-1 text-sm" {...props}>
          {authors.length > 0 ? (
            getSignature(authors.map((s) => s.longName.trim()).filter(Boolean))
          ) : (
            <span className="text-grey-on">{placeholder ?? "Aucun"}</span>
          )}
        </Clickable>
      );
    },
  ),
);

type ArticleAuthorsSelectorProps = {
  placeholder?: ArticleAuthorsButton["placeholder"];
  tooltip?: string;
  article?: ArticleAuthorsSelect_articleFragment;
} & ArticleAuthorsSelectStateProps;

export const ArticleAuthorsSelector = ({
  placeholder,
  tooltip,
  article,
  ...props
}: ArticleAuthorsSelectorProps) => {
  const state = useArticleAuthorsSelectState(props);
  const open = useStoreState(state.select, "open");
  const previousOpen = usePrevious(open);

  const store = useDialogStore();
  const { logEvent } = useAmplitude();

  const authorsRef = useRef(props.value);

  const [notifiedAuthors, setNotifiedAuthors] = useState<ArticleAuthor[]>([]);

  useEffect(() => {
    if (!article) return;
    const addedAuthors = differenceBy(props.value, authorsRef.current, "id");
    // only take into account authors who are Sirius users
    const linkedAuthors = addedAuthors.filter((author) => Boolean(author.user));
    if (previousOpen && !open && linkedAuthors.length) {
      setNotifiedAuthors(linkedAuthors);
      logNotificationsEvent({
        logEvent,
        eventType: "Signatory Added",
        authors: addedAuthors,
        article,
      });

      store.toggle();
    }
    if (!open) {
      authorsRef.current = props.value;
    }
  }, [open, previousOpen, props.value, store, logEvent, article]);

  return (
    <span onClick={(event) => event.stopPropagation()}>
      <Tooltip tooltip={open ? null : (tooltip ?? "Éditer les signataires")}>
        <SelectButton asChild store={state.select}>
          <ArticleAuthorsButton
            authors={props.value}
            placeholder={placeholder}
          />
        </SelectButton>
      </Tooltip>
      <SelectPopover store={state.select} combobox modal>
        <ArticleAuthorsSelectList state={state} />
      </SelectPopover>
      {article?.id && (
        <AuthorsNotificationDialog
          authors={notifiedAuthors}
          store={store}
          article={article}
        />
      )}
    </span>
  );
};
