import {
  Dialog,
  DialogDisclosure,
  DialogStore,
  useDialogStore,
} from "swash/Dialog";
import { PageLoader } from "swash/Loader";
import { PanelBody, PanelHeader } from "swash/Panel";
import { Tooltip } from "swash/Tooltip";
import { useEditorContext } from "swash/editor";
import { useStoreState } from "swash/utils/useStoreState";

import { useIntegrationHealthCheck } from "@/components/rich-editor/utils/IntegrationHealthCheck";

import { MerciAppEditors } from "./MerciAppEditors";
import { MerciAppProvider } from "./MerciAppProvider";
// @ts-expect-error PNG file
import logo from "./merciapp.png";
import { useMerciAppToken } from "./useMerciAppToken";

const MerciAppDialogContent = ({ store }: { store: DialogStore }) => {
  const { loading, authToken } = useMerciAppToken();

  if (!loading && !authToken) {
    return null;
  }

  return (
    <MerciAppProvider token={authToken}>
      <PanelHeader
        title={
          <img
            src={logo}
            alt="MerciApp"
            className="h-7"
            style={{ aspectRatio: "64 / 15" }}
          />
        }
        onClose={store.hide}
      />
      <PanelBody>{!loading ? <MerciAppEditors /> : <PageLoader />}</PanelBody>
    </MerciAppProvider>
  );
};

export const MerciAppDialog = ({ store }: { store: DialogStore }) => {
  const open = useStoreState(store, "open");
  return (
    <Dialog
      store={store}
      style={{ width: 968 }}
      aria-label="Correcteur MerciApp"
      hideOnInteractOutside={false}
      hideOnEscape={false}
    >
      {open && <MerciAppDialogContent store={store} />}
    </Dialog>
  );
};

export function MerciAppSpellCheckControl({
  render,
}: {
  render: React.ReactElement;
}) {
  const dialog = useDialogStore();

  const { editor } = useEditorContext();
  const integration = useIntegrationHealthCheck("merciapp");

  if (!editor) return null;

  if (!integration.enabled) return null;

  return (
    <>
      <Tooltip
        tooltip={
          !integration.isHealthy
            ? "Le service tiers Merci App rencontre actuellement un incident et nous indique qu’il est actuellement indisponible."
            : undefined
        }
      >
        <DialogDisclosure store={dialog} render={render} />
      </Tooltip>
      <MerciAppDialog store={dialog} />
    </>
  );
}
