import { InputRule, Node, PasteRule, mergeAttributes } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import Suggestion, { SuggestionOptions } from "@tiptap/suggestion";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    emoji: {
      setEmoji: (name: string) => ReturnType;
    };
  }
}

type EmojiData = {
  name: string;
  title: string;
  encodedImage: string;
};

export type EmojiNodeOptions = {
  HTMLAttributes: Record<string, any>;
  emojis: EmojiData[];
  enableEmoticons: boolean;
  forceFallbackImages: boolean;
  suggestion: Omit<SuggestionOptions<string>, "editor">;
};

export const EmojiNode = Node.create<EmojiNodeOptions>({
  name: "emoji",
  inline: true,
  group: "inline",
  selectable: false,

  addOptions() {
    return {
      HTMLAttributes: {},
      emojis: [],
      enableEmoticons: false,
      forceFallbackImages: false,
      suggestion: {
        char: ":",
        pluginKey: new PluginKey("emojiSuggestion"),
        command: ({ editor, range, props }) => {
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(" ");
          if (overrideSpace) {
            range.to += 1;
          }
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              { type: this.name, attrs: props },
              { type: "text", text: " " },
            ])
            .command(
              ({ tr, state }) => (
                tr.setStoredMarks(
                  state.doc.resolve(state.selection.to - 2).marks(),
                ),
                true
              ),
            )
            .run();
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const type = state.schema.nodes[this.name]!;
          const allow = !!$from.parent.type.contentMatch.matchType(type);
          return allow;
        },
        items: ({ query, editor }) => {
          const emojis: EmojiData[] = editor.storage[this.name].getEmojis();
          return emojis
            .filter((emoji) =>
              emoji.name.toLowerCase().startsWith(query.toLowerCase()),
            )
            .map((emoji) => emoji.name);
        },
      },
    };
  },

  addStorage() {
    return {
      getEmojis: () => this.options.emojis,
    };
  },

  addAttributes() {
    return {
      name: {
        default: null,
        parseHTML: (el) => el.dataset.name,
        renderHTML: (attr) => ({ "data-name": attr.name }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    const emoji = findEmoji(node.attrs.name, this.options.emojis);
    const attributes = mergeAttributes(
      HTMLAttributes,
      this.options.HTMLAttributes,
      {
        "data-type": this.name,
      },
    );
    if (!emoji) return ["span", attributes, `:${node.attrs.name}:`];
    return [
      "span",
      attributes,
      [
        "img",
        {
          src: emoji.encodedImage,
          draggable: "false",
          loading: "lazy",
          align: "absmiddle",
        },
      ],
    ];
  },

  renderText({ node }) {
    return `:${node.attrs.name}:`;
  },

  addCommands() {
    return {
      setEmoji:
        (name) =>
        ({ chain }) => {
          const emoji = findEmoji(name, this.options.emojis);
          if (emoji) {
            chain()
              .insertContent({ type: this.name, attrs: { name: emoji.name } })
              .command(
                ({ tr, state }) => (
                  tr.setStoredMarks(
                    state.doc.resolve(state.selection.to - 1).marks(),
                  ),
                  true
                ),
              )
              .run();
            return true;
          } else {
            return false;
          }
        },
    };
  },

  addInputRules() {
    const rules: InputRule[] = [];
    rules.push(
      new InputRule({
        find: /:([a-zA-Z0-9_+-]+):$/,
        handler: ({ range, match, chain }) => {
          const name = match[1];
          if (findEmoji(name, this.options.emojis)) {
            chain()
              .insertContentAt(range, { type: this.name, attrs: { name } })
              .command(
                ({ tr, state }) => (
                  tr.setStoredMarks(
                    state.doc.resolve(state.selection.to - 1).marks(),
                  ),
                  true
                ),
              )
              .run();
          }
        },
      }),
    );
    return rules;
  },

  addPasteRules() {
    return [
      new PasteRule({
        find: /:([a-zA-Z0-9_+-]+):/g,
        handler: ({ range, match, chain }) => {
          const name = match[1];
          if (findEmoji(name, this.options.emojis)) {
            chain()
              .insertContentAt(
                range,
                { type: this.name, attrs: { name } },
                { updateSelection: false },
              )
              .command(
                ({ tr, state }) => (
                  tr.setStoredMarks(
                    state.doc.resolve(state.selection.to - 1).marks(),
                  ),
                  true
                ),
              )
              .run();
          }
        },
      }),
    ];
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({ editor: this.editor, ...this.options.suggestion }),
      new Plugin({
        key: new PluginKey("emoji"),
        props: {
          handleDoubleClickOn: (_view, pos, node) => {
            if (node.type !== this.type) return false;
            const from = pos;
            const to = from + node.nodeSize;
            return this.editor.commands.setTextSelection({ from, to }), true;
          },
        },
      }),
    ];
  },
});

function findEmoji(name: string | undefined, emojis: EmojiData[]) {
  if (!name) return;
  return emojis.find((emoji) => name === emoji.name);
}
