import { DocumentNode, TypedDocumentNode, gql } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-final-form";
import { Button } from "swash/Button";
import { DialogDisclosure, useDialogStore } from "swash/Dialog";
import { IoCogOutline } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { GlobalId } from "@/components/GlobalId";
import {
  CheckboxField,
  useCheckboxField,
} from "@/components/fields/CheckboxField";
import { FieldsetField } from "@/components/fields/Fieldset";
import { FormSavingIndicator } from "@/components/forms/FormSavingIndicator";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { useSafeQuery } from "@/containers/Apollo";
import { HasLevelAccess } from "@/containers/User";
import type {
  SnippetFragmentFragment,
  SnippetsConfigQueryQuery,
} from "@/gql-types";

import {
  type ColorMode,
  type Device,
  StatusBar,
  StatusBarColorModeGroup,
  StatusBarDeviceGroup,
  StatusBarGroup,
  StatusBarSpacer,
} from "../common/EditorStatusBar";
import { EditorField } from "../fields/EditorField";
import { SnippetParamsDialog } from "./SnippetDialogs";

type ISnippet = SnippetFragmentFragment;

// TODO: Type properly
type Editor = any;

type Operations = {
  UsedInQueries: {
    UsedInArticlesQuery: DocumentNode;
    UsedInPostsQuery: DocumentNode;
  };
};

const ConfigQuery: TypedDocumentNode<SnippetsConfigQueryQuery, never> = gql`
  query SnippetsConfigQuery {
    dbConfig {
      snippets {
        contextHtml
        darkModeAttributes
      }
    }
  }
`;

const SettingsDialogIconButton: React.FC<{
  snippet: ISnippet;
  editor: Editor;
  operations: Operations;
}> = ({ editor, snippet, operations }) => {
  const dialog = useDialogStore();

  return (
    <>
      <DialogDisclosure
        store={dialog}
        render={
          <Button
            appearance="text"
            variant="secondary"
            iconOnly
            scale="sm"
            aria-label="Paramètres"
          >
            <IoCogOutline />
          </Button>
        }
      />
      <SnippetParamsDialog
        editor={editor}
        snippet={snippet}
        operations={operations}
        store={dialog}
        mode={undefined}
      />
    </>
  );
};

const useSrcDoc = ({
  rawDarkModeAttributes,
  contextHtml,
  code,
  colorMode,
}: {
  rawDarkModeAttributes?: string;
  contextHtml?: string;
  code: string;
  colorMode: ColorMode;
}) => {
  const darkModeAttributes = useMemo(() => {
    try {
      return JSON.parse(rawDarkModeAttributes || "null") ?? {};
    } catch {
      return {};
    }
  }, [rawDarkModeAttributes]);

  const wrappedCode = `
  <script>
  // Silent all errors before the script is running
  window.onerror = (e) => console.error("Snippet error: " + e) || true
  </script>
  ${contextHtml || ""}
  ${code}
  <script>
    // Silent all errors after the script is running
    window.onerror = (e) => console.error("Snippet error: " + e) || true
  </script>
  `;

  return useMemo(() => {
    if (colorMode === "light") return wrappedCode;
    const element = document.createElement("div");
    Object.entries(darkModeAttributes || {}).forEach(([key, value]) => {
      element.setAttribute(key, String(value));
    });
    const [, start, end] = element.outerHTML.match(/(.*)(<\/div>)$/)!;
    return `${start}${wrappedCode}${end}`;
  }, [colorMode, wrappedCode, darkModeAttributes]);
};

const SnippetCardIframe: React.FC<{
  rawDarkModeAttributes?: any;
  contextHtml?: string;
  code: string;
  colorMode: ColorMode;
  device: Device;
}> = ({ rawDarkModeAttributes, contextHtml, code, colorMode, device }) => {
  const srcDoc = useSrcDoc({
    rawDarkModeAttributes,
    contextHtml,
    code,
    colorMode,
  });
  const width = (() => {
    switch (device) {
      case "mobile":
        return 375; // iPhone X
      case "desktop":
      default:
        return "100%";
    }
  })();
  return (
    <iframe
      title="SnippetCard"
      srcDoc={srcDoc}
      frameBorder={0}
      marginWidth={0}
      marginHeight={0}
      style={{
        backgroundColor: "white",
        margin: "0 auto",
        border: 0,
        width,
        height: "100%",
        // @ts-expect-error Exotic CSS props
        frameBorder: "0",
        marginWidth: "0",
        marginHeight: "0",
      }}
      sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-same-origin"
    />
  );
};

const SnippetCard: React.FC<{
  snippet: ISnippet;
  editor: Editor;
  operations: Operations;
}> = ({ editor, snippet, operations }) => {
  const code = useSubscribeFormValue("code");
  const [device, setDevice] = useState<Device>("mobile");
  const [colorMode, setColorMode] = useState<ColorMode>("light");

  const { data } = useSafeQuery(ConfigQuery);
  if (!data) return null;

  return (
    <div className="flex h-full flex-col border-4 border-l-0 border-grey-border-strong bg-dusk-bg-stronger focus:outline-none [&_button:focus]:text-inherit [&_button:hover]:text-inherit">
      <div className="relative flex-auto text-center">
        <SnippetCardIframe
          code={code}
          rawDarkModeAttributes={data.dbConfig.snippets?.darkModeAttributes}
          contextHtml={data.dbConfig.snippets?.contextHtml ?? undefined}
          colorMode={colorMode}
          device={device}
        />
      </div>
      <StatusBar className="shrink-0 border-t border-grey-border-strong">
        <StatusBarDeviceGroup value={device} onChange={setDevice} />
        <StatusBarColorModeGroup value={colorMode} onChange={setColorMode}>
          <DarkModeField
            colorMode={colorMode}
            disabled={snippet.forceDarkModeSupported}
          />
        </StatusBarColorModeGroup>
        <StatusBarSpacer />
        <StatusBarGroup>
          <SettingsDialogIconButton
            editor={editor}
            snippet={snippet}
            operations={operations}
          />
        </StatusBarGroup>
      </StatusBar>
    </div>
  );
};

const DarkModeField: React.FC<{
  disabled?: boolean;
  colorMode: ColorMode;
}> = ({ disabled, colorMode }) => {
  const name = "darkMode";
  const form = useForm();
  const field = useCheckboxField(name, {
    format: (v) => v === "supported",
    parse: (v) => (v ? "supported" : "notSupported"),
    disabled,
  });

  useEffect(() => {
    if (disabled) {
      form.change(name, "supported");
    }
  }, [form, disabled]);

  if (colorMode !== "dark") return null;

  return (
    <Tooltip
      tooltip={
        disabled ? (
          <>
            <div>
              Le dark mode a été activé de manière automatique grâce au
              commentaire :
            </div>
            <code className="font-mono">{"<!-- dark-mode-support -->"}</code>
          </>
        ) : undefined
      }
    >
      <CheckboxField {...field}>Compatible mode sombre</CheckboxField>
    </Tooltip>
  );
};

const SnippetCodeEditorHeader: React.FC<{
  snippet: ISnippet;
}> = ({ snippet: { globalId, createdAt, updatedAt } }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex p-2 pt-0">
        <HasLevelAccess level="developer">
          {globalId ? <GlobalId globalId={globalId} /> : null}
        </HasLevelAccess>
      </div>
      <div className="flex p-2 pt-0">
        <FormSavingIndicator
          // @ts-expect-error JS component
          date={updatedAt ?? createdAt}
        />
      </div>
    </div>
  );
};

const SnippetCodeEditorContent: React.FC<{
  snippet: ISnippet;
  editor: Editor;
  operations: Operations;
}> = ({ editor, snippet, operations }) => {
  return (
    <div className="flex grow flex-wrap">
      <FieldsetField className="flex-[3] p-0">
        <EditorField name="code" lang="html" className="h-full" />
      </FieldsetField>
      <FieldsetField className="flex-[2] p-0">
        <SnippetCard
          editor={editor}
          snippet={snippet}
          operations={operations}
        />
      </FieldsetField>
    </div>
  );
};

export const SnippetCodeEditor: React.FC<{
  snippet: ISnippet;
  editor: Editor;
  operations: Operations;
}> = ({ snippet, editor, operations }) => {
  return (
    <div className="flex h-full flex-col">
      <SnippetCodeEditorHeader snippet={snippet} />
      <SnippetCodeEditorContent
        editor={editor}
        snippet={snippet}
        operations={operations}
      />
    </div>
  );
};
