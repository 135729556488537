import { Editor, posToDOMRect } from "@tiptap/core";
import { ReactRenderer } from "@tiptap/react";
import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { PopoverCard } from "swash/Popover";
import { computePosition, flip, shift } from "swash/floating";

const MIN_LENGTH = 1;

export const EmojiSuggestionList = forwardRef<
  { onKeyDown: (props: SuggestionKeyDownProps) => boolean },
  SuggestionProps<string>
>(function EmojiSuggestionList(props, ref) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ name: item });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length,
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (props.query.length < MIN_LENGTH) return false;

      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  if (props.query.length < MIN_LENGTH) return null;

  return (
    <PopoverCard className="dropdown-menu p-2">
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={
              index === selectedIndex ? "is-selected font-semibold" : ""
            }
            key={index}
            onClick={() => selectItem(index)}
          >
            {item}
          </button>
        ))
      ) : (
        <div className="item">No result</div>
      )}
    </PopoverCard>
  );
});

const updatePosition = (editor: Editor, element: Element) => {
  const virtualElement = {
    getBoundingClientRect: () =>
      posToDOMRect(
        editor.view,
        editor.state.selection.from,
        editor.state.selection.to,
      ),
  };

  const style = (element as HTMLElement).style;
  style.position = "absolute";

  computePosition(virtualElement, element as HTMLElement, {
    placement: "bottom-start",
    strategy: "absolute",
    middleware: [shift(), flip()],
  }).then(({ x, y, strategy }) => {
    style.width = "max-content";
    style.position = strategy;
    style.left = `${x}px`;
    style.top = `${y}px`;
  });
};

export const renderEmojiSuggestionList = () => {
  let reactRenderer: ReactRenderer<
    {
      onKeyDown: (props: SuggestionKeyDownProps) => boolean;
    },
    SuggestionProps<any, any>
  >;

  return {
    onStart: (props: SuggestionProps<string>) => {
      if (!props.clientRect) return;
      reactRenderer = new ReactRenderer(EmojiSuggestionList, {
        props,
        editor: props.editor,
      });
      document.body.appendChild(reactRenderer.element);
      updatePosition(props.editor, reactRenderer.element);
    },

    onUpdate(props: SuggestionProps<string>) {
      reactRenderer.updateProps(props);
      if (!props.clientRect) return;
      updatePosition(props.editor, reactRenderer.element);
    },

    onKeyDown(props: SuggestionKeyDownProps) {
      if (props.event.key === "Escape") {
        reactRenderer.destroy();
        reactRenderer.element.remove();
        return true;
      }
      return reactRenderer.ref?.onKeyDown(props) ?? false;
    },

    onExit() {
      reactRenderer.destroy();
      reactRenderer.element.remove();
    },
  };
};
