import { Extension, InputRule, PasteRule } from "@tiptap/core";

import {
  SetTypographicRulesActiveTransaction,
  TypographicRulesPlugin,
  TypographicRulesPluginKey,
  TypographicRulesPluginState,
} from "./TypographicRulesPlugin";
import * as apostrophe from "./rules/apostrophe";
import * as numbers from "./rules/numbers";
import * as punctuation from "./rules/punctuation";
import * as quotes from "./rules/quotes";

export type TypographicRulesOptions = {
  typographicalCorrection: boolean;
  italicsBetweenQuotes: boolean;
};

const activableInputRule = (rule: InputRule) => {
  return new InputRule({
    find: rule.find,
    handler: (props) => {
      const active = TypographicRulesPluginKey.getState(
        props.state,
      )?.typographicalCorrection;
      if (!active) return;
      return rule.handler(props);
    },
  });
};

const activablePasteRule = (rule: PasteRule) => {
  return new PasteRule({
    find: rule.find,
    handler: (props) => {
      const active = TypographicRulesPluginKey.getState(
        props.state,
      )?.typographicalCorrection;
      if (!active) return;
      return rule.handler(props);
    },
  });
};

export const TypographicRulesExtension =
  Extension.create<TypographicRulesOptions>({
    name: "typographicRules",

    addOptions() {
      return {
        typographicalCorrection: true,
        italicsBetweenQuotes: true,
      };
    },

    addInputRules() {
      return [
        activableInputRule(apostrophe.inputRule()),
        activableInputRule(numbers.inputRule()),
        activableInputRule(punctuation.inputRule()),
        activableInputRule(quotes.inputRule()),
      ];
    },

    addPasteRules() {
      return [
        activablePasteRule(apostrophe.pasteRule()),
        activablePasteRule(numbers.pasteRule()),
        activablePasteRule(punctuation.pasteRule()),
        activablePasteRule(quotes.pasteRule()),
        new PasteRule({
          find: /«\s*(.+?)\s*»/g,
          handler({ range, state }) {
            const active =
              TypographicRulesPluginKey.getState(state)?.italicsBetweenQuotes;
            if (!active) return;

            state.tr.addMark(range.from, range.to, state.schema.mark("italic"));
          },
        }),
      ];
    },

    addProseMirrorPlugins() {
      return [
        TypographicRulesPlugin({
          typographicalCorrection: this.options.typographicalCorrection ?? true,
          italicsBetweenQuotes: this.options.italicsBetweenQuotes ?? true,
        }),
      ];
    },

    addCommands() {
      return {
        setActiveTypographicalCorrection:
          (active = true) =>
          ({ dispatch, tr }) => {
            if (dispatch) {
              const payload: Partial<TypographicRulesPluginState> = {
                typographicalCorrection: active,
              };
              tr.setMeta(SetTypographicRulesActiveTransaction, payload);
            }
            return true;
          },

        toggleTypographicalCorrection:
          () =>
          ({ dispatch, tr, state }) => {
            const target =
              !TypographicRulesPluginKey.getState(state)
                ?.typographicalCorrection;
            if (dispatch) {
              const payload: Partial<TypographicRulesPluginState> = {
                typographicalCorrection: target,
              };
              tr.setMeta(SetTypographicRulesActiveTransaction, payload);
            }
            return true;
          },

        setActiveItalicsBetweenQuotes:
          (active = true) =>
          ({ dispatch, tr }) => {
            if (dispatch) {
              const payload: Partial<TypographicRulesPluginState> = {
                italicsBetweenQuotes: active,
              };
              tr.setMeta(SetTypographicRulesActiveTransaction, payload);
            }
            return true;
          },

        toggleItalicsBetweenQuotes:
          () =>
          ({ dispatch, tr, state }) => {
            const target =
              !TypographicRulesPluginKey.getState(state)?.italicsBetweenQuotes;
            if (dispatch) {
              const payload: Partial<TypographicRulesPluginState> = {
                italicsBetweenQuotes: target,
              };
              tr.setMeta(SetTypographicRulesActiveTransaction, payload);
            }
            return true;
          },
      };
    },

    addKeyboardShortcuts() {
      return {
        "Shift-Mod-F": () => {
          return this.editor.commands.toggleTypographicalCorrection();
        },
        "Shift-Mod-G": () => {
          return this.editor.commands.toggleItalicsBetweenQuotes();
        },
      };
    },
  });

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    typographicRules: {
      setActiveTypographicalCorrection: (active?: boolean) => ReturnType;
      toggleTypographicalCorrection: () => ReturnType;

      setActiveItalicsBetweenQuotes: (active?: boolean) => ReturnType;
      toggleItalicsBetweenQuotes: () => ReturnType;
    };
  }
}
