import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Heading } from "@tiptap/extension-heading";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Strike } from "@tiptap/extension-strike";
import { Subscript } from "@tiptap/extension-subscript";
import { Superscript } from "@tiptap/extension-superscript";
import { Underline } from "@tiptap/extension-underline";

import { InvisibleCharactersExtension } from "../extensions/invisible-characters";
import { SpecialCharactersExtension } from "../extensions/special-characters";
import { TypographicRulesExtension } from "../extensions/typographic-rules";
import { EmojiNode } from "../nodes/emoji";
import { Core, createKit } from "./base";

export const RichTextKit = createKit("richTextKit", () => ({
  ...Core,

  // Nodes
  heading: Heading.configure({ levels: [2] }),
  bulletList: BulletList,
  listItem: ListItem, // TODO: Allows `sinkListItem`, but it's not configurable through options. Should we consider extending it? This may cause breaking changes.
  orderedList: OrderedList,
  blockquote: Blockquote,
  horizontalRule: HorizontalRule,
  emoji: EmojiNode,

  // Marks
  bold: Bold,
  italic: Italic,
  strike: Strike,
  subscript: Subscript,
  superscript: Superscript,
  underline: Underline,

  // Functionality
  invisibleCharacters: InvisibleCharactersExtension,
  specialCharacters: SpecialCharactersExtension,
  typographicRules: TypographicRulesExtension,
}));
