import { gql } from "@apollo/client";
import { IoExpand, IoVideocam } from "swash/Icon";
import {
  PreviewLink,
  PreviewLinkContent,
  PreviewLinkHovering,
} from "swash/PreviewLink";
import { cn } from "swash/utils/classNames";

import {
  DialogDisclosure,
  VideoPreviewDialog,
  useDialogStore,
} from "@/containers/video/VideoPreview";

import { ChangeText } from "./ChangeText";
import {
  EditorNodeBody,
  EditorNodeCard,
  EditorNodeIcon,
  EditorNodeImage,
  EditorNodeLayout,
  EditorNodeTooltip,
} from "./NodeLayout";

/**
 * @param {?} props
 */
export function VideoNodeExpanded({ video, dropping }) {
  return (
    <div
      className={cn(
        '[[data-change="deleted"]_&]:relative [[data-change="deleted"]_&]:text-danger-on [[data-change="deleted"]_&]:line-through',
        '[[data-change="deleted"]_&]:before:absolute [[data-change="deleted"]_&]:before:inset-0 [[data-change="deleted"]_&]:before:bg-danger-bg [[data-change="deleted"]_&]:before:opacity-25',
        '[[data-change="deleted"]_&]:after:absolute [[data-change="deleted"]_&]:after:text-[500px] [[data-change="deleted"]_&]:after:text-white [[data-change="deleted"]_&]:after:content-["×"]',
        '[[data-change="added"]_&]:relative [[data-change="added"]_&]:text-success-on',
        '[[data-change="added"]_&]:before:absolute [[data-change="added"]_&]:before:inset-0 [[data-change="added"]_&]:before:bg-success-bg [[data-change="added"]_&]:before:opacity-25',
      )}
    >
      <iframe
        title="Video"
        className={cn(
          dropping ? "pointer-events-none" : "pointer-events-auto",
          "mt-10 h-96 w-full",
        )}
        src={video.playerUrl}
        data-test-hidden
      />
    </div>
  );
}

/**
 * @param {?} props
 */
export function VideoNode({ video, tooltip, expanded, dropping }) {
  const previewDialog = useDialogStore();

  return !expanded ? (
    <>
      <EditorNodeCard>
        <EditorNodeLayout>
          <EditorNodeTooltip tooltip={tooltip}>
            <EditorNodeIcon>
              <IoVideocam />
            </EditorNodeIcon>
          </EditorNodeTooltip>
          {video.thumbnailUrl && (
            <DialogDisclosure
              title="Prévisualiser la vidéo"
              store={previewDialog}
              render={
                <PreviewLink>
                  <PreviewLinkContent display="flex">
                    <EditorNodeImage src={video.thumbnailUrl} />
                  </PreviewLinkContent>
                  <PreviewLinkHovering>
                    <IoExpand />
                  </PreviewLinkHovering>
                </PreviewLink>
              }
            />
          )}
          <EditorNodeBody multiline>
            <ChangeText rawText={video.videoTitle}>
              {video.videoTitle}
            </ChangeText>
          </EditorNodeBody>
        </EditorNodeLayout>
      </EditorNodeCard>
      {video.thumbnailUrl && (
        <VideoPreviewDialog store={previewDialog} videoId={video.id} />
      )}
    </>
  ) : (
    <VideoNodeExpanded video={video} dropping={dropping} />
  );
}

VideoNode.fragments = {
  video: gql`
    fragment VideoNode_video on Video {
      videoTitle: title
      thumbnailUrl
      playerUrl
      textDescription
      url
    }
  `,
};
