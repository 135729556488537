import { Editor } from "@tiptap/react";
import { ReactNode, forwardRef } from "react";

import { Button } from "../../Button";
import { EllipsisVertical } from "../../Icon";
import { Menu, MenuButton, MenuProvider } from "../../Menu";
import { useEditorContext } from "../index";
import { createFixedMenu } from "../utils/createFixedMenu";

export const FixedMenu = forwardRef<HTMLDivElement, FixedMenuProps>(
  ({ editor, children }, ref) => {
    const { editor: currentEditor } = useEditorContext();
    editor = editor || currentEditor;

    return createFixedMenu(
      <MenuProvider>
        <MenuButton
          render={
            <Button scale="xs" iconOnly variant="secondary" appearance="text" />
          }
          className="fixed-menu-button absolute right-1 top-1 z-10"
          ref={ref}
        >
          <EllipsisVertical />
          <span className="sr-only">More options</span>
        </MenuButton>
        <Menu portal>{children}</Menu>
      </MenuProvider>,
      editor,
      "fixed-menu",
    );
  },
);

interface FixedMenuProps {
  editor?: Editor | null;
  children: ReactNode;
}
