import { gql } from "@apollo/client";
import moment from "moment-timezone";
import "moment/locale/fr";
import React, { useEffect, useMemo, useState } from "react";
import { useField, useForm } from "react-final-form";
import { IoCalendarOutline } from "swash/Icon";
import {
  Select,
  SelectClear,
  SelectPopover,
  useSelectStore,
} from "swash/Select";
import { SingleDatePicker } from "swash/controls/DatePicker";
import { Radio, RadioGroup, RadioLabel } from "swash/controls/Radio";
import { TimePickerInput } from "swash/controls/TimePickerInput";
import { shallowEqual } from "swash/utils/shallowEqual";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useStoreState } from "swash/utils/useStoreState";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

import { Time } from "@/components/Time";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { SelectField, useSelectField } from "@/components/fields/SelectField";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { formatDate } from "@/containers/article/ArticlePublishDate";
import { HourRange } from "@/containers/article/ArticlePublishTime";
import { dateToParts, partsToDate } from "@/containers/article/util/dateParts";
import { useSlotItems } from "@/containers/article/util/publicationSlots";

const SelectDatePickerLabel = ({ placeholder, value }) => {
  if (!value.datetime) {
    return <div className="text-grey-on">{placeholder}</div>;
  }
  const date = moment(value.datetime.date).tz("Europe/Paris");

  const [hours, minutes] = value.datetime.time.split(":");

  date.set("hour", hours);
  date.set("minute", minutes);
  return (
    <Time date={date}>
      <div className="flex items-center gap-2">
        <IoCalendarOutline className="text-secondary-on" />
        <div className="max-w-40 truncate">
          {formatDate(date)} |{" "}
          {value.range === "hour" ? (
            <HourRange date={date} dateFormat="HH[h]" />
          ) : (
            `${hours}:${minutes}`
          )}
        </div>
      </div>
    </Time>
  );
};

const RangePickerField = ({ plannedDate, name, onChange: parentOnChange }) => {
  const items = useSlotItems({ plannedDate });

  const {
    input: { value },
  } = useField(name, {
    format: (value) =>
      items.find((range) => range.from === dateToParts(value.date)?.time) ??
      null,
    parse: (value) => {
      return value?.from || null;
    },
  });

  const enumSelect = useEnumSelectState({
    value: value || null,
    onChange: parentOnChange,
    items,
    labelSelector: (range) =>
      range ? `${range.from.split(":")[0]}h - ${range.to.split(":")[0]}h` : "",
    valueSelector: (range) => range?.from,
    required: true,
  });

  return (
    <EnumSelect
      state={enumSelect}
      aria-label="Créneau horaire"
      placeholder="Créneau horaire"
    />
  );
};

export const ArticleExposureDateTimeFields = ({
  correlatedFieldName,
  hasSlotOption,
  hasTimeOption,
  name,
  onChange,
  value: propValue,
}) => {
  const form = useForm();
  const plannedDate = useSubscribeFormValue("publicationDate.plannedDate");
  const valueDate = useMemo(
    () =>
      propValue?.date
        ? dateToParts(propValue.date)
        : dateToParts(moment.tz(plannedDate ?? new Date(), "Europe/Paris")),
    [plannedDate, propValue],
  );

  const [dateParts, setDateParts] = useState(valueDate);
  const [timingMode, setTimingMode] = useState(propValue?.range);
  const refs = useLiveRef({ onChange, valueDate, dateParts });

  // Trigger `onChange` when values change
  useEffect(() => {
    const { valueDate, onChange } = refs.current;
    if (!shallowEqual(valueDate, dateParts)) {
      form.change(correlatedFieldName, false);
      onChange(
        dateParts.date === null && dateParts.time === null
          ? null
          : { date: partsToDate(dateParts), range: timingMode },
      );
    }
  }, [dateParts, correlatedFieldName, form, refs, timingMode]);

  // Synchronize input values
  useEffect(() => {
    const { dateParts } = refs.current;
    if (!shallowEqual(valueDate, dateParts)) {
      setDateParts(valueDate);
    }
  }, [valueDate, refs]);

  // Synchronize timingMode values
  useEffect(() => {
    setTimingMode(propValue?.range);
  }, [propValue?.range]);

  const handleDateChange = (date) => {
    if (!date) {
      return;
    }
    setDateParts((dateParts) => ({
      time: dateParts.time ?? "00:00",
      date,
    }));
  };

  const handleTimeSlotChange = (value) => {
    setDateParts((dateParts) => ({
      time: value.from,
      date: dateParts.date ?? formatDate(new Date(), "yyyy-MM-dd"),
    }));
  };

  const handleTimeChange = (value) => {
    setDateParts((dateParts) => ({
      time: value,
      date: dateParts.date ?? formatDate(new Date(), "yyyy-MM-dd"),
    }));
  };

  const handleTimingModeChange = (event) => {
    setTimingMode(event.target.value);
  };

  return (
    <div className="flex flex-row justify-between p-4">
      <div className="mt-1.5 flex flex-col gap-1">
        <SingleDatePicker
          onChange={handleDateChange}
          value={dateParts.date}
          modifiers={{
            disabled: {
              before: new Date(),
            },
          }}
        />
      </div>
      <div className="w-60 pl-3 pr-1">
        <RadioGroup className="flex flex-col">
          {hasTimeOption ? (
            <RadioLabel className="mt-2">
              <Radio
                onChange={handleTimingModeChange}
                checked={timingMode === "time"}
                value="time"
              />
              Heure
            </RadioLabel>
          ) : null}
          {timingMode === "time" && hasTimeOption && (
            <div className="mb-2 pl-8">
              <div className="flex flex-col gap-1 pt-2">
                <TimePickerInput
                  onChange={handleTimeChange}
                  value={dateParts.time.substring(0, 5)}
                />
              </div>
            </div>
          )}
          {hasSlotOption ? (
            <RadioLabel className="mt-2">
              <Radio
                onChange={handleTimingModeChange}
                checked={timingMode === "hour"}
                value="hour"
              />
              Créneau horaire
            </RadioLabel>
          ) : null}
          {timingMode === "hour" && (
            <div className="mt-1 pl-8">
              <RangePickerField
                onChange={handleTimeSlotChange}
                plannedDate={partsToDate(dateParts)}
                name={name}
              />
            </div>
          )}
        </RadioGroup>
      </div>
    </div>
  );
};

const DateSlotSelectControl = ({
  ariaLabel,
  correlatedFieldName,
  hasSlotOption,
  hasTimeOption,
  name,
  onChange,
  value: propValue,
}) => {
  const select = useSelectStore({
    value: propValue,
    setValue: onChange,
  });
  const value = useStoreState(select, "value");
  const form = useForm();

  return (
    <>
      <Select store={select} aria-label={ariaLabel}>
        <SelectDatePickerLabel
          placeholder={"Date et heure"}
          value={{ datetime: dateToParts(value?.date), range: value.range }}
        />
        <SelectClear
          store={select}
          onClear={() => {
            select.setValue({ date: null, range: null });
            form.change(correlatedFieldName, false);
          }}
        />
      </Select>
      <SelectPopover aria-label="Date et heure" store={select} modal>
        <ArticleExposureDateTimeFields
          correlatedFieldName={correlatedFieldName}
          name={name}
          onChange={select.setValue}
          hasSlotOption={hasSlotOption}
          hasTimeOption={hasTimeOption}
          value={value}
        />
      </SelectPopover>
    </>
  );
};

function formatRange(range, hasSlotOption) {
  if (range) {
    return range;
  }

  if (hasSlotOption) {
    return "hour";
  }

  return "time";
}

export function ExposurePlannedDateField({
  correlatedFieldName,
  exposure,
  disabled,
  label,
  name,
  scale,
  ...options
}) {
  const hasSlotOption =
    ["slot", "slot_and_hour"].includes(exposure.plannableMode) ||
    exposure.type === "tag";

  const hasTimeOption = exposure.type !== "tag";

  const field = useSelectField(name, {
    parse: ({ date, range }) => {
      return date ? { date, range } : { date: null, range: null };
    },
    format: (planning) => ({
      date: planning?.date,
      range: formatRange(planning?.range),
    }),
  });

  return (
    <FieldGroup {...field}>
      <FieldError {...field} />
      <SelectField
        {...field}
        ariaLabel="Date et heure"
        as={DateSlotSelectControl}
        correlatedFieldName={correlatedFieldName}
        disabled={disabled}
        hasSlotOption={hasSlotOption}
        hasTimeOption={hasTimeOption}
        name={name}
        {...options}
      />
    </FieldGroup>
  );
}

ExposurePlannedDateField.fragments = {
  exposure: gql`
    fragment ExposurePlanneDateField_exposure on Exposure {
      plannableMode
    }
  `,
};
