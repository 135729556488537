import { Editor } from "@tiptap/react";
import { Children, ReactNode, useEffect, useState } from "react";

import { FixedMenuPlugin } from "../extensions/fixed-menu";

export const createFixedMenu = (
  children: ReactNode,
  editor: Editor | null,
  pluginKey: string,
  portal = true,
) => {
  return (() => {
    const [element, setElement] = useState<HTMLDivElement | null>(null);
    useEffect(() => {
      if (!element) {
        return;
      }

      if (!editor || editor?.isDestroyed) {
        return;
      }

      const plugin = FixedMenuPlugin({
        editor,
        element,
        pluginKey,
        portal,
      });

      editor.registerPlugin(plugin);
      return () => {
        editor.unregisterPlugin(pluginKey);
      };
    }, [element]);
    return (
      <div className="contents" ref={setElement}>
        {Children.only(children)}
      </div>
    );
  })();
};
