import { forwardRef, memo } from "react";
import { IoSparkles } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";

import { Image } from "@/components/Image";

export const EditorNodeCard = memo(
  forwardRef<
    HTMLDivElement,
    React.ComponentProps<"div"> & {
      borderColor?: string;
      variant?: string;
      expanded?: boolean;
    }
  >(({ borderColor, variant, expanded, ...props }, ref) => {
    return (
      <div
        ref={ref}
        data-variant={variant}
        data-expanded={expanded ? "" : undefined}
        data-border-colored={Boolean(borderColor)}
        {...props}
        style={{
          borderLeftColor: borderColor,
          ...props.style,
        }}
        className={cn(
          "select-none bg-grey-bg-light",
          !borderColor && "rounded",
          variant === "danger" && "bg-danger-bg-light",
          variant === "valid" && "bg-white",
          expanded &&
            cn(
              "bg-grey-bg-light p-6",
              variant === "side-panel" && "bg-white p-0",
              "data-[editor-block-capsule-sidebar]:bg-success-on-light",
              "[&[readonly]]:bg-yellow-100",
              "[&_[data-editor-block-capsule-body]]:px-0",
              "[&_[data-editor-block-capsule-sidebar]]:w-0 [&_[data-editor-block-capsule-sidebar]]:p-0",
            ),
          "[&[data-expanded]_[data-border-colored]:border border-grey-border-light",
          borderColor ? "border-l-[3px]" : "pl-[3px]",
          expanded
            ? cn(
                "relative",
                '[[data-change="deleted"]_&]:before:absolute [[data-change="deleted"]_&]:before:inset-0 [[data-change="deleted"]_&]:before:bg-danger-bg [[data-change="deleted"]_&]:before:opacity-25',
                '[[data-change="deleted"]_&]:after:absolute [[data-change="deleted"]_&]:after:text-[500px] [[data-change="deleted"]_&]:after:text-white [[data-change="deleted"]_&]:after:content-["×"]',
                '[[data-change="added"]_&]:before:absolute [[data-change="added"]_&]:before:inset-0 [[data-change="added"]_&]:before:bg-success-bg [[data-change="added"]_&]:before:opacity-25',
              )
            : cn(
                '[[data-change="deleted"]_&]:bg-danger-bg [[data-change="deleted"]_&]:text-danger-on-strong',
                '[[data-change="added"]_&]:bg-success-bg [[data-change="added"]_&]:text-success-on-strong',
              ),
          props.className,
        )}
      />
    );
  }),
);

export const EditorNodeLayout = (props: React.ComponentProps<"div">) => (
  <div
    className="flex items-center gap-2 p-2 text-sm text-dusk-on"
    {...props}
  />
);

export const EditorNodeIcon = forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(function EditorNodeIcon(props, ref) {
  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        "mr-1 flex shrink-0 items-center gap-2",
        '[[data-variant="loading"]_&]:opacity-disabled',
        "[&_svg]:size-5",
        '[[data-change="added"]_&]:text-danger-on-strong [[data-change="deleted"]_&]:text-success-on-strong',
        props.className,
      )}
    />
  );
});

export const EditorNodeGenAIIcon = () => {
  return (
    <IoSparkles
      role="status"
      aria-label="Recommandé par l’assistant"
      className="!h-3.5 !w-3.5 text-purple-on"
    />
  );
};

export const EditorNodeImage = ({
  src,
  width: widthProp,
  height: heightProp,
  loading = false,
  expanded,
  ...props
}: Omit<React.ComponentProps<"img">, "loading"> & {
  loading?: boolean;
  expanded?: boolean;
}) => {
  const width = expanded ? "100%" : (widthProp ?? 72);
  const height = expanded ? "auto" : (heightProp ?? 52);

  return (
    <div
      className={cn(
        "relative flex items-center justify-center bg-black",
        loading && "opacity-disabled",
        '[[data-change="deleted"]_&]:before:absolute [[data-change="deleted"]_&]:before:inset-0 [[data-change="deleted"]_&]:before:bg-danger-bg [[data-change="deleted"]_&]:before:opacity-25',
        '[[data-change="deleted"]_&]:after:absolute [[data-change="deleted"]_&]:after:text-[500px] [[data-change="deleted"]_&]:after:text-white [[data-change="deleted"]_&]:after:content-["×"]',
        '[[data-change="added"]_&]:before:absolute [[data-change="added"]_&]:before:inset-0 [[data-change="added"]_&]:before:bg-success-bg [[data-change="added"]_&]:before:opacity-25',
        props.className,
      )}
    >
      {!loading && (
        <Image
          src={src}
          {...props}
          className="pointer-events-none shrink-0 overflow-hidden object-contain"
          style={{
            width,
            height,
            maxWidth: "none",
            maxHeight: "3000px",
            ...props.style,
          }}
        />
      )}
    </div>
  );
};

export const EditorNodeBody = ({
  multiline,
  variant,
  children,
}: {
  multiline?: boolean;
  variant?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      data-variant={variant}
      data-multiline={multiline ? "" : undefined}
      className={cn(
        "flex-auto overflow-hidden text-ellipsis",
        "overflow-hidden text-ellipsis *:leading-none",
        multiline ? "line-clamp-2 *:line-clamp-2" : "whitespace-nowrap",
        variant === "loading" && "opacity-disabled",
      )}
    >
      {children}
    </div>
  );
};

export const EditorNodeTooltip = ({
  tooltip,
  children,
}: {
  tooltip?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return tooltip ? (
    <Tooltip tooltip={tooltip} placement="left">
      <div>{children}</div>
    </Tooltip>
  ) : (
    children
  );
};
