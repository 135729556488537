import { Node, mergeAttributes } from "@tiptap/core";

export interface TitleOptions {
  HTMLAttributes: Record<string, any>;
}

export const Title = Node.create<TitleOptions>({
  name: "title",

  content: "inline*",

  // No marks allowed in a title
  marks: "",

  group: "headline",

  defining: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [{ tag: "h1" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "h1",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => {
        // @TODO Implement draft behavior
        return this.editor.commands.moveToNextNodeEnd();
      },
      Tab: () => {
        return this.editor.commands.moveToNextNodeEnd();
      },
    };
  },
});
