import { forwardRef } from "react";

import { SimpleTextEditorSwitcher } from "@/components/editor/SimpleTextEditor";
import {
  FieldControl,
  FieldControlProps,
} from "@/components/fields/FieldControl";
import { useTextPreset } from "@/containers/editor/presets/preset-text";

export const SimpleTextEditorField = forwardRef<
  HTMLDivElement,
  SimpleTextEditorFieldProps
>(({ as = SimpleTextEditorSwitcher, ...props }, ref) => {
  const plugins = useTextPreset();
  return (
    <FieldControl
      ref={ref}
      as={as}
      // @ts-expect-error temporary code
      plugins={plugins}
      {...props}
    />
  );
});

interface SimpleTextEditorFieldProps extends FieldControlProps<"div"> {}
