import { canUseDOM } from "@ariakit/core/utils/dom";
import { useUpdateEffect } from "@ariakit/react-core/utils/hooks";
import * as TipTapCore from "@tiptap/core";
import * as TipTap from "@tiptap/react";
import { type VariantProps, cva } from "class-variance-authority";
import {
  CSSProperties,
  DependencyList,
  HTMLProps,
  PropsWithChildren,
  createContext,
  forwardRef,
  useContext,
  useRef,
} from "react";
import { twMerge } from "tailwind-merge";

export type Editor = TipTapCore.Editor;

export const useEditor = (
  options: useEditorOptions = {},
  deps: DependencyList = [],
) => {
  const editor = TipTap.useEditor(
    {
      // prevent SSR issues with Next swash/website
      immediatelyRender: canUseDOM,
      // For performance reasons. Either way, the next TipTap version will set this to `false` by default.
      shouldRerenderOnTransaction: false,
      ...options,
      editorProps: {
        attributes: {
          ...options?.editorProps?.attributes,
          class:
            // @ts-expect-error class is a valid key of `attributes`
            `editor ${options?.editorProps?.attributes?.class || ""}`.trim(),
        },
      },
    },
    deps,
  );

  const editable = options.editable;
  const editorRef = useRef<TipTapCore.Editor | null>(editor);
  editorRef.current = editor;

  useUpdateEffect(() => {
    if (!editorRef.current || typeof editable === "undefined") return;
    editorRef.current.setEditable(editable);
  }, [editable]);

  return editor;
};

interface useEditorOptions extends TipTap.UseEditorOptions {}

export interface EditorContextValue extends TipTap.EditorContextValue {}

export const EditorContext = createContext<EditorContextValue>({
  editor: null,
});
/**
 * A hook to get the current editor instance.
 */
export const useEditorContext = () => useContext(EditorContext);

/**
 * This is the provider component for the editor.
 * It allows the editor to be accessible across the entire component tree
 * with `useEditorContext`.
 */
export function EditorProvider({
  children,
  ...editorOptions
}: EditorProviderProps) {
  const editor = useEditor(editorOptions);

  return (
    <EditorContext.Provider value={{ editor }}>
      {children}
    </EditorContext.Provider>
  );
}

export interface EditorProviderProps
  extends PropsWithChildren<useEditorOptions> {}

export const Editor = forwardRef<HTMLDivElement, EditorProps>(
  ({ editor, scale, intent, appearance, rows, maxRows, ...props }, ref) => {
    const context = useEditorContext();

    editor = editor || context.editor;

    return (
      <EditorContext.Provider value={{ editor }}>
        <TipTap.EditorContent
          editor={editor}
          innerRef={ref}
          {...props}
          className={twMerge(
            editorVariants({
              scale,
              intent,
              appearance,
              className: props.className,
            }),
          )}
          style={
            {
              "--editor-rows": rows,
              "--editor-max-rows": maxRows,
              ...props.style,
            } as CSSProperties
          }
        />
      </EditorContext.Provider>
    );
  },
);

// This goes hand in hand with `editor.css`
const editorVariants = cva("editor-container [&_.editor]:scrollbar-light", {
  variants: {
    appearance: {
      textBox: [
        "rounded-md outline outline-1 outline-secondary-border-light",
        "focus-within:ring has-[.editor[contenteditable='false']]:opacity-40",
      ],
      editor: "rich",
      inline: [
        "rounded-md outline outline-1 outline-transparent focus-within:ring",
        "hover:[&:not(:focus-within)]:!outline-transparent",
      ],
    },
    intent: {
      danger: "outline-danger-border focus-within:ring-danger-border/30",
      primary: [
        "focus-within:outline-primary-border focus-within:ring-primary-border/30",
        "has-[.editor[contenteditable='true']]:hover:outline-primary-border",
      ],
    },
    scale: {
      xs: "text-xs [&_.editor]:py-1",
      sm: "text-sm [&_.editor]:py-1",
      base: null,
      lg: "text-lg",
      xl: "text-xl",
    },
  },
  defaultVariants: {
    intent: "primary",
    scale: "lg",
    appearance: "editor",
  },
});

export type EditorVariantProps = VariantProps<typeof editorVariants>;

export interface EditorProps
  extends HTMLProps<HTMLDivElement>,
    EditorVariantProps {
  /**
   * Object returned by the `useEditor` hook. If not provided, the closest
   * `EditorProvider` component will be used.
   */
  editor?: TipTapCore.Editor | null;

  /**
   * This is similar to the [`rows`](https://developer.mozilla.org/en-US/docs/Web/API/HTMLTextAreaElement/rows)
   * attribute in a textarea element.
   */
  rows?: number;

  /**
   * Restricts the editor to a maximum number of rows. If the content exceeds this limit,
   * the editor will enable vertical scrolling.
   * @example 5
   */
  maxRows?: number;
}
